/* fix for weird blue-border for google map */
.gm-style iframe + div {
  border: none !important;
}

/* removes 'x' button in google maps marker's info window */
button.gm-ui-hover-effect {
  visibility: hidden;
}

/* removes blank space in InfoWindow */
.gm-style-iw-chr {
  display: none !important;
}
