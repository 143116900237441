/**
Basic Table Styles
 */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;
}

table.simple thead tr th {
  padding: 16px 8px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

table.simple thead tr th:first-child {
  padding-left: 24px;
}

table.simple thead tr th:last-child {
  padding-right: 24px;
}

table.simple tbody tr td {
  padding: 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
  padding-left: 24px;
}

table.simple tbody tr td:last-child {
  padding-right: 24px;
}

table.simple tbody tr:last-child td {
  border-bottom: none;
}

table.simple.clickable tbody tr {
  cursor: pointer;
}

table.simple.clickable tbody tr:hover {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable.custom-sorting .rt-tbody .rt-tr-group {
  border-bottom: 0;
}

.ReactTable.custom-sorting .rt-resizable-header {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable.custom-sorting .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 16px;
  top: 0;
  bottom: 0;
  right: -8px;
  cursor: col-resize;
  z-index: 10;
}

div.rt-table > div.rt-thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
