.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before {
  background-color: rgba(45, 236, 61, 0.71) !important;
}

.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted {
  color: white;
}

.Cal__Day__highlighted,
.Cal_Day_range {
  .Cal__Day__selection {
    color: rgba(45, 236, 61, 0.92) !important;
  }
}

.no-data-message {
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  padding: 1.25rem;
  font-weight: normal;
}

.MuiTab-root {
  max-width: 500px;
  white-space: unset;
}

.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-18 16:25:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
