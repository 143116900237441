.typography-body2 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
}

.typography-caption {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
}

.color-blue-100 {
  color: #039be5;
}

.color-purple-100 {
  color: #5438dc;
}

.typography-h6 {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
}

.typography-h5 {
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
}

.typography-subtitle2 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
}

.typography-input-label {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
}
